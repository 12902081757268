export const messageContainerStyle = () => {
	return {
		alignSelf: "flex-start",
		marginBottom: "16px",
		paddingLeft: "16px",
		paddingRight: "16px",
		maxWidth: "65%",
		clear: "both",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		flexShrink: "0",
	};
};

export const messageWrapperStyle = () => {
	return {
		width: "auto",
		flex: "1 1",
		alignSelf: "flex-start",
		display: "flex",
	};
};

export const messageThumbnailStyle = () => {
	return {
		width: "36px",
		height: "36px",
		margin: "10px 5px",
		float: "left",
		flexShrink: "0",
	};
};

export const messageDetailStyle = (context) => {
	if (context.type === 'group') {
		return {
		  borderRadius: "25px",
		  border: "1px solid var(--V3-Colors-Line, #CBD6E2)",
		  background: "var(--V3-Colors-BG2, #F5F8FA)",
		  padding: "8px 20px 15px 20px",
		};
	  }
	return {
		flex: "1 1",
		display: "flex",
		flexDirection: "column",
	};
};

export const nameWrapperStyle = (avatar) => {
	const paddingValue = avatar
		? {
				padding: "3px 5px",
		  }
		: {};

	return {
		alignSelf: "flex-start",
		...paddingValue,
	};
};

export const nameStyle = (context,props) => {
	// console.log("context",context);

	return {
		fontSize: "14px",
		//  color: `${context.type === 'group' ? '#494F73' : context.theme.color.search}`,
		color: `${
			context.type === 'group'
			  ? props?.message?.sender?.role === 'prospect'
				? '#4E156B'
				: props?.message?.sender?.role === 'ambassador'
				? '#9C2B5F'
				: props?.message?.sender?.role === 'staff'
				? '#C66F37'
				: context.theme.color.search
			  : context.theme.color.search
		  }`,
	};
};

export const messageTxtContainerStyle = () => {
	return {
		width: "auto",
		flex: "1 1",
		display: "flex",
		alignSelf: "flex-start",
	};
};
export const messageTxtWrapperStyle = (context) => {
	if(context.type==='group'){
		return {
			display: "inline-block",
		width: "auto",
		marginLeft:"0px !important"
		  }
	}
	return {
	  display: "inline-block",
	  borderRadius: "25px", // Consolidated borderRadius
	  padding: "15px 20px",
	  width: "auto",
	  marginLeft: "0px !important", // Corrected margin property
	  border: "1px solid var(--V3-Colors-Line, #CBD6E2)",
	  background: "var(--V3-Colors-BG2, #F5F8FA)",
	};
  };
  
//   export const messageTxtStyle = (showVariation, count, context) => {
// 	let emojiAlignmentProp = {
// 	  "> img": {
// 		width: "24px",
// 		height: "24px",
// 		display: "inline-block",
// 		verticalAlign: "top",
// 		zoom: "1",
// 		margin: "0 2px",
// 	  },
// 	};
  
// 	let emojiProp = {};
  
// 	if (count === 1) {
// 	  emojiProp = {
// 		"> img": {
// 		  width: "48px",
// 		  height: "48px",
// 		},
// 	  };
// 	} else if (count === 2) {
// 	  emojiProp = {
// 		"> img": {
// 		  width: "36px",
// 		  height: "36px",
// 		},
// 	  };
// 	} else if (count > 2) {
// 	  emojiProp = {
// 		"> img": {
// 		  width: "24px",
// 		  height: "24px",
// 		},
// 	  };
// 	}
  
// 	if (showVariation === false) {
// 	  emojiProp = {
// 		"> img": {
// 		  width: "24px",
// 		  height: "24px",
// 		},
// 	  };
// 	}
  
// 	return {
// 	  margin: "0",
// 	  fontSize: "16px", // Updated font size
// 	  whiteSpace: "pre-wrap",
// 	  wordBreak: "break-word",
// 	  textAlign: "left",
// 	  width: "auto",
// 	  color: "var(--V3-Colors-TL-Main, #494F73)", // Updated color
// 	  lineHeight: "24px", // Updated line height
// 	  fontWeight: "300", // Updated font weight
// 	  fontFamily: "Lexend Deca", // Updated font family
// 	  fontStyle: "normal", // Updated font style
// 	  " a": {
// 		color: "#0432FF",
// 		"&:hover": {
// 		  color: "#04009D",
// 		},
// 	  },
// 	  " a[href^='mailto:']": {
// 		color: "#F38C00",
// 		"&:hover": {
// 		  color: "#F36800",
// 		},
// 	  },
// 	  " a[href^='tel:']": {
// 		color: "#3802DA",
// 		"&:hover": {
// 		  color: "#2D038F",
// 		},
// 	  },
// 	  ...emojiAlignmentProp,
// 	  ...emojiProp,
// 	};
//   };


export const messageTxtStyle = (showVariation, count, context) => {
	let emojiAlignmentProp = {
	  "> img": {
		width: "24px",
		height: "24px",
		display: "inline-block",
		verticalAlign: "top",
		zoom: "1",
		margin: "0 2px",
	  },
	};
  
	let emojiProp = {};
  
	if (count === 1) {
	  emojiProp = {
		"> img": {
		  width: "48px",
		  height: "48px",
		},
	  };
	} else if (count === 2) {
	  emojiProp = {
		"> img": {
		  width: "36px",
		  height: "36px",
		},
	  };
	} else if (count > 2) {
	  emojiProp = {
		"> img": {
		  width: "20px",
		  height: "20px",
		  display: "inline-block",
		  verticalAlign: "sub",
		},
	  };
	}
  
	if (showVariation === false) {
	  emojiProp = {
		"> img": {
		  width: "24px",
		  height: "24px",
		},
	  };
	}
  
	// Base styles
	const baseStyle = {
		margin: context.type === 'group' ? "0px 5px" : "0",
		fontSize: "15px",
		whiteSpace: "pre-wrap",
		wordBreak: "break-word",
		textAlign: "left",
		width: "auto",
		color: `${context.theme.color.primary}`,
		lineHeight: "20px",
		fontWeight: "400",
		" a": {
		   color: "#0432FF",
		   
		  "&:hover": {
			color: "#04009D",

		  },
		},
		" a[href^='mailto:']": {
		  color: "#F38C00",
		  "&:hover": {
			color: "#F36800",
		  },
	  },
	  " a[href^='tel:']": {
		color: "#3802DA",
		"&:hover": {
		  color: "#2D038F",
		},
	  },
	  ...emojiAlignmentProp,
	  ...emojiProp,
	};

	if (context.type === 'group') {
		return {
		  ...baseStyle,
		  color: "var(--V3-Colors-TL-Main, #494F73)",
		  fontFamily: "Lexend Deca",
		  fontSize: "16px",
		  fontStyle: "normal",
		  fontWeight: "300",
		  lineHeight: "24px",
		};
	  }
  
	  return baseStyle;
	};

  

	export const messageInfoWrapperStyle = (context) => {
		const paddingCheck = context?.type === 'group' ? "4px 46px" : "4px 4px";
		return {
			alignSelf: "flex-start",
			padding: "4px 4px",
			padding: paddingCheck,
		alignItems: "center",
		justifyContent: "flex-start",
		height: "25px",
	};
};

export const messageReactionsWrapperStyle = () => {
	return {
		display: "flex",
		alignSelf: "flex-start",
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		minHeight: "36px",
	};
};

export const nameRole = () => {
	return {
	  color: 'var(--V3-Colors-Placeholder, #8F98A3)',
	  fontFamily: '"Lexend Deca", sans-serif',
	  fontSize: '14px',
	  fontStyle: 'normal',
	  fontWeight: 300,
	  lineHeight: '24px',
	textTransform: "capitalize",


	};
  };
  export const circle = () => {
	return {
	  width: '5px',
	  height: '5px',
	  display: 'inline-block',
	  borderRadius: '50%', 
	  backgroundColor: '#CBD6E2', 
	  margin: '1px 5px',
	};
  };