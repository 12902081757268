import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import "./index.scss";
import { COMETCHAT_CONSTANTS } from "./environment";
import {
  CometChatConversationListWithMessages,
  CometChatGroupListWithMessages,
} from "./comet-chat";
import { getDataFromParams } from "./getUrlParams";
import { initializeAnalytics } from "./googleAnalytics";



// new code add by me in 23-04-2024
// document.addEventListener("DOMContentLoaded", function () {
  const fontColor=getDataFromParams("fontColor")
  const Mode = getDataFromParams("Mode");
  const appID = COMETCHAT_CONSTANTS[Mode].APP_ID;
  const region = COMETCHAT_CONSTANTS[Mode].REGION;
  const authKey = COMETCHAT_CONSTANTS[Mode].AUTH_KEY;
  const LoginUserUid = getDataFromParams("Set1");
  const ChatToUserUid = getDataFromParams("Set2");
  const ChatTypeIs = getDataFromParams("Type");
  const LoginType = getDataFromParams("LoginType");
  const chatType = getDataFromParams("chatType");
  const groupid = getDataFromParams("group");
  const clarityId = getDataFromParams("msclarityId");
  document.documentElement.style.setProperty("--theme-color", fontColor);

  const gtagId = getDataFromParams("gantId");
  const setUserTags=getDataFromParams('setUserTags')
 try {
  if(gtagId){
    initializeAnalytics(gtagId);
  }
 } catch (error) {
  // console.log(error);
 }

 const autoSocket = setUserTags === "prospect" ? false : true;
 console.log("AutoSocket setting:", autoSocket);
 
 const appSetting = new CometChat.AppSettingsBuilder()
   .subscribePresenceForAllUsers()
   .setRegion(region)
   .autoEstablishSocketConnection(autoSocket)
   .build();
  // console.log(LoginUserUid);
  // console.log("clarityId",clarityId)  
  const shouldOpenFirstChat = ChatToUserUid !== "" ? false : true;

  const isChatingWithSupport = true;
  let shouldShowInputBox = true;
  if (LoginType === 1) {
    if (LoginUserUid === "00000000t011" || LoginUserUid === "100000000000") {
      shouldShowInputBox = true;
    } else {
      shouldShowInputBox = false;
    }
  }

  // console.log("shouldShowInputBox index.js", shouldShowInputBox);
  function decrypt(message) {
    let shift = 23;
    let decryptedMessage = "";
    let specialValue = "#$^%&*#$";
    if (!message.includes(specialValue)) {
      return message;
    } else {
      message = message.replaceAll("#$^%&*#$", "");
      for (let i = 0; i < message.length; i++) {
        const charCode = message.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) {
          decryptedMessage += String.fromCharCode(
            ((charCode - 65 + shift) % 26) + 65
          );
        } else if (charCode >= 97 && charCode <= 122) {
          decryptedMessage += String.fromCharCode(
            ((charCode - 97 + shift) % 26) + 97
          );
        } else {
          decryptedMessage += message[i];
        }
      }
      return decryptedMessage;
    }
  }

  function decryptedName(name) {
    return decrypt(name);
  }

  function insertClarityScript(clarityId) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      (function(c,l,a,r,i,t,y) {
        c[a] = c[a] || function() { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "${clarityId}");
    `;
    document.head.appendChild(script);
  }
  
  if (clarityId) {
    insertClarityScript(clarityId);
  }

  ReactDOM.render(
      <BrowserRouter>
        <div
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              border: "10px solid #f3f3f3",
              borderTop: "10px solid #3498db",
              borderRadius: "50%",
              width: "80px",
              height: "80px",
              animation: "spin 1s linear infinite",
            }}
          ></div>
        </div>
      </BrowserRouter>,
    document.getElementById("root")
  );

  // Add your click event listener here
  window.addEventListener('click', function () {
    window.parent.postMessage('iframeClick', '*');
  });

  CometChat.init(appID, appSetting)
    .then(
      async () => {
        if (CometChat.setSource) {
          CometChat.setSource("ui-kit", "web", "reactjs");
        }
        try {
          let resp = await CometChat.logout();
          console.log("Previous User Logged-out Successfully");
        } catch (e) {
          console.log(e);
        } finally {
          CometChat.login(LoginUserUid, authKey).then(
            (user) => {
              console.log("New User Loged in Successful:", { user });
              console.log(ChatTypeIs);

              // get conversation count
              let limit = 30;
              let conversationsRequest =
                new CometChat.ConversationsRequestBuilder()
                  .setLimit(limit)
                  .build();

              conversationsRequest.fetchNext().then(
                (conversationList) => {
                  localStorage.setItem("totalMessage", conversationList.length);
                  if (conversationList.length > 0) {
                    // calling laravel commetchat blade function
                    // try {
                    //   showchat();
                    // } catch (error) {
                    //   console.log("showchat function is not available");
                    // }
                  } else {
                    // try {
                    //   hidechat();
                    // } catch (error) {
                    //   console.log("hidechat function is not available");
                    // }
                  }
                },
                (error) => {
                  console.log(
                    "Conversations list fetching failed with error:",
                    error
                  );
                }
              );
              // end conversation count

              ReactDOM.render(
                  <BrowserRouter>
                    {ChatTypeIs === 1 ? (
                      <div
                        style={{
                          height: "30px",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                          color: "#494F73",
                          textTransform: "capitalize",
                          display: "none",
                        }}
                      >
                        Responding as {decryptedName(user?.name)}
                      </div>
                    ) : null}

                    {LoginType === 1 ? (
                      <div
                        style={{
                          height: "100vh",
                          width: "100%",
                          background: "#ffffff",
                          border: "1px solid #cbd6e2",
                          // borderRadius: "10px",
                          overflow: "hidden",
                          display: chatType === 1 ? "none" : "block",
                        }}
                        className="chathide"
                      >
                        <CometChatConversationListWithMessages
                          chatWithUser={ChatToUserUid?.toString()}
                          shouldOpenFirstChat={shouldOpenFirstChat}
                          shouldShowInputBox={shouldShowInputBox}
                          isChatingWithSupport={isChatingWithSupport}
                        />
                      </div>
                    ) : chatType == 5 ? (
                      <div
                        style={{
                          height: "100vh",
                          background: "#ffffff",
                          border: "1px solid #cbd6e2",
                          // borderRadius: "10px",
                          overflow: "hidden",
                          display: chatType === 1 ? "none" : "block",
                        }}
                        className="chathide"
                      >
                        {/* {console.log(groupid+'------>')} */}
                        {/* <CometChatGroupListWithMessages */}
                         <CometChatConversationListWithMessages
                        chatWithGroup={groupid?.toString()}
                        // shouldOpenFirstChat={shouldOpenFirstChat}
                        // shouldShowInputBox={shouldShowInputBox}
                        // isRespondingAsAmbassador={ChatTypeIs}
                        // isChatingWithSupport={isChatingWithSupport}
                        shouldOpenFirstChat={shouldOpenFirstChat}
                        shouldShowInputBox={shouldShowInputBox}
                        isRespondingAsAmbassador={ChatTypeIs}
                        isChatingWithSupport={isChatingWithSupport}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "100vh",
                          background: "#ffffff",
                          border: "1px solid #cbd6e2",
                          // borderRadius: "10px",
                          overflow: "hidden",
                          display: chatType === 1 ? "none" : "block",
                        }}
                        className="chathide"
                      >
                        <CometChatConversationListWithMessages
                          chatWithUser={ChatToUserUid?.toString()}
                          shouldOpenFirstChat={shouldOpenFirstChat}
                          shouldShowInputBox={shouldShowInputBox}
                          isRespondingAsAmbassador={ChatTypeIs}
                          isChatingWithSupport={isChatingWithSupport}
                        />
                      </div>
                    )}
                  </BrowserRouter>,
                document.getElementById("root")
              );
              // setPinnedUser(ChatToUserUid);
            },
            (error) => {
              console.log("Login failed with exception:", { error });
              setTimeout(() => {
                window.parent.location.href =
                  window.parent.location.protocol +
                  "//" +
                  window.parent.location.hostname +
                  "/conversations";
              }, 1500);
            }
          );
        }
      },
      (error) => {
        console.log("Initialization failed with error:", error);
        setTimeout(() => {
          window.parent.location.href =
            window.parent.location.protocol +
            "//" +
            window.parent.location.hostname +
            "/conversations";
        }, 1500);
      }
    )
    .catch((e) => {
      console.log(e);
    });

