import React from "react";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat/chat-sdk-javascript";

import {
  CometChatSmartReplyPreview,
  CometChatCreatePoll,
  CometChatStickerKeyboard,
} from "../Extensions";
import { CometChatEmojiKeyboard } from "../";

import { CometChatContext } from "../../../util/CometChatContext";
import {
  checkMessageForExtensionsData,
  ID,
  getUnixTimestamp,
} from "../../../util/common";
import * as enums from "../../../util/enums.js";
import { SoundManager } from "../../../util/SoundManager";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";
import { gtag } from "../../../../googleAnalytics.js";

import {
  chatComposerStyle,
  editPreviewContainerStyle,
  previewHeadingStyle,
  previewCloseStyle,
  previewTextStyle,
  composerInputStyle,
  inputInnerStyle,
  messageInputStyle,
  inputStickyStyle,
  stickyAttachmentStyle,
  filePickerStyle,
  fileListStyle,
  fileItemStyle,
  stickyAttachButtonStyle,
  stickyButtonStyle,
  emojiButtonStyle,
  sendButtonStyle,
  reactionBtnStyle,
  stickerBtnStyle,
  // Record Audio
  timerStyle,
  stopButtonStyle,
  deleteButtonStyle,
  recorderButtonStyle,
  attachstyle,
} from "./style";

import roundedPlus from "./resources/add-circle-filledd.svg";
import videoIcon from "./resources/video.svg";
import audioIcon from "./resources/audio-file.svg";
import docIcon from "./resources/file-uploads.svg";
import imageIcon from "./resources/image.svg";
import insertEmoticon from "./resources/emoji.svg";
import sendBlue from "./resources/send-message.svg";
import pollIcon from "./resources/polls.svg";
import stickerIcon from "./resources/stickers.svg";
import closeIcon from "./resources/close1.svg";
import micIcon from "./resources/mic.svg";
import pauseIcon from "./resources/pause.svg";
import playIcon from "./resources/play.svg";
import clearIcon from "./resources/clear.svg";
import documentIcon from "./resources/collaborative-document.svg";
import whiteboardIcon from "./resources/collaborative-whiteboard.svg";
import heartIcon from "./resources/heart.png";
import moment from "moment";
import { getDataFromParams } from "../../../../getUrlParams.js";
import { initializeAnalytics } from "../../../../googleAnalytics.js";
import {COMETCHAT_CONSTANTS} from '../../../../environment.js'

class CometChatMessageComposer extends React.PureComponent {
  static contextType = CometChatContext;
  range;
  sel;
  chatWindow;
  constructor(props) {
    super(props);
    (this.hasEventFired = false), (this.imageUploaderRef = React.createRef());
    this.fileUploaderRef = React.createRef();
    this.audioUploaderRef = React.createRef();
    this.videoUploaderRef = React.createRef();
    this.messageInputRef = React.createRef();
    this.liveReactionInProgress = false;
    this.isTyping = false;

    this.state = {
      showFilePicker: false,
      messageInput: "",
      messageType: "",
      emojiViewer: false,
      createPoll: false,
      messageToBeEdited: "",
      replyPreview: null,
      stickerViewer: false,
      messageToReact: "",
      shareDocument: false,
      shareWhiteboard: false,
      enableLiveReaction: false,
      enablePolls: false,
      enableTypingIndicator: false,
      enableStickers: false,
      enablePhotosVideos: false,
      enableFiles: false,
      enableEmojis: false,
      enableCollaborativeDocument: false,
      enableCollaborativeWhiteboard: false,
      // Recorder States
      audioName: "",
      audioBlobUrl: "",
      recordingTime: "00:00",
      audioRecordedTime: "",
      stream: null,
      recorder: null,
      interval: null,
      startTime: null,
      isPlaying: false,
      recordedBlob: null,
      showAudioSendBtn: false,
      isAudioRecording: false,
      isAudioStopped: false,
      isShowRecorderIcon: true,
      showRecorderdiv: true,
      originalHeight: 21,

      lineCount: null,
        manualStatus: localStorage.getItem("manualStatus") === "true"
    };
  }

  componentDidMount() {  
      
    document.addEventListener("click", this.onDocumentClick);
    CometChat.getLoggedinUser()
      .then((user) => (this.loggedInUser = user))
      .catch((error) =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
    this.chatWindow = this.context.UIKitSettings.chatWindow;
    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER ||
      this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.context.item
        : null;
    this.enableLiveReaction();
    this.enablePolls();
    this.enableTypingIndicator();
    this.enableStickers();
    this.enablePhotosVideos();
    this.enableFiles();
    this.enableEmojis();
    this.enableCollaborativeDocument();
    this.enableCollaborativeWhiteboard();
    this.countLines();

   const textarea = this.messageInputRef.current;

  if (textarea) {
    // Add listener for paste event
    textarea.addEventListener('paste', this.handlePaste);
  }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.messageToBeEdited !== this.props.messageToBeEdited) {
      const messageToBeEdited = this.props.messageToBeEdited?.data?.text || "";
      // console.log(messageToBeEdited,"messageToBeEdited",this.props);

      this.setState(
        {
          messageInput: messageToBeEdited,
          messageToBeEdited: messageToBeEdited,
        },
        () => {
          try {
            let element = this.messageInputRef.current;
            // Only adjust height if text exceeds one line
            if (element && this.state.messageInput.length > 0) {
              this.adjustHeight(element);
            }
          } catch (error) {
            console.log(error);
          }
        }
      );

      const element = this.messageInputRef.current;
      if (messageToBeEdited) {
        // console.log(messageToBeEdited,"messageToBeEdited--2");
        let messageText = messageToBeEdited?.data?.text || "";

        //xss extensions data
        const xssData = checkMessageForExtensionsData(
          messageToBeEdited,
          "xss-filter"
        );
        if (
          xssData &&
          xssData.hasOwnProperty("sanitized_text") &&
          xssData.hasOwnProperty("hasXSS") &&
          xssData.hasXSS === "yes"
        ) {
          messageText = xssData.sanitized_text;
        }

        element.focus();
        element.textContent = "";
        // this.pasteHtmlAtCaret(messageText);
      } else {
        element.textContent = "";
      }
    }

    if (prevProps.replyPreview !== this.props.replyPreview) {
      this.setState({ replyPreview: this.props.replyPreview });
    }

    const previousMessageStr = JSON.stringify(prevProps.messageToReact);
    const currentMessageStr = JSON.stringify(this.props.messageToReact);

    if (previousMessageStr !== currentMessageStr) {
      this.setState({ messageToReact: this.props.messageToReact });
    }

    if (this.context.item !== this.item) {
      this.messageInputRef.current.textContent = "";
      this.setState({
        stickerViewer: false,
        emojiViewer: false,
        showFilePicker: false,
        replyPreview: null,
        messageToBeEdited: "",
        messageInput: "",
      });

      this.focusOnMessageComposer();
    }

    if (prevState.messageInput !== this.state.messageInput) {
      this.focusOnMessageComposer();
    }

    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER ||
      this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP
        ? this.context.item
        : null;
    this.enableLiveReaction();
    this.enablePolls();
    this.enableTypingIndicator();
    this.enableStickers();
    this.enablePhotosVideos();
    this.enableFiles();
    this.enableEmojis();
    this.enableCollaborativeDocument();
    this.enableCollaborativeWhiteboard();
    this.countLines();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
    const textarea = this.messageInputRef.current;

    if (textarea) {
      textarea.removeEventListener('paste', this.handlePaste);
    }
  }

  handlePaste = (event) => {
    setTimeout(() => {
      const textarea = this.messageInputRef.current;
      if (textarea) {
        this.adjustHeight(textarea);
      }
    }, 0); 
  };
  /**
   * if live reactions feature is disabled
   */
  enableLiveReaction = () => {
    this.context.FeatureRestriction.isLiveReactionsEnabled()
      .then((response) => {
        if (response !== this.state.enableLiveReaction) {
          this.setState({ enableLiveReaction: false });
        }
      })
      .catch((error) => {
        if (this.state.enableLiveReaction !== false) {
          this.setState({ enableLiveReaction: false });
        }
      });
  };

  /**
   * if polls feature is disabled
   */
  enablePolls = () => {
    this.context.FeatureRestriction.isPollsEnabled()
      .then((response) => {
        if (response !== this.state.enablePolls) {
          this.setState({ enablePolls: response });
        }
      })
      .catch((error) => {
        if (this.state.enablePolls !== false) {
          this.setState({ enablePolls: false });
        }
      });
  };

  /**
   * if typing indicator feature is disabled
   */
  enableTypingIndicator = () => {
    this.context.FeatureRestriction.isTypingIndicatorsEnabled()
      .then((response) => {
        if (response !== this.state.enableTypingIndicator) {
          this.setState({ enableTypingIndicator: response });
        }
      })
      .catch((error) => {
        if (this.state.enableTypingIndicator !== false) {
          this.setState({ enableTypingIndicator: false });
        }
      });
  };

  /**
   * if stickers feature is disabled
   */
  enableStickers = () => {
    this.context.FeatureRestriction.isStickersEnabled()
      .then((response) => {
        if (response !== this.state.enableStickers) {
          this.setState({ enableStickers: response });
        }
      })
      .catch((error) => {
        if (this.state.enableStickers !== false) {
          this.setState({ enableStickers: false });
        }
      });
  };

  /**
   * if uploding photos, videos feature is disabled
   */
  enablePhotosVideos = () => {
    this.context.FeatureRestriction.isPhotosVideosEnabled()
      .then((response) => {
        if (response !== this.state.enablePhotosVideos) {
          this.setState({ enablePhotosVideos: response });
        }
      })
      .catch((error) => {
        if (this.state.enablePhotosVideos !== false) {
          this.setState({ enablePhotosVideos: false });
        }
      });
  };

  /**
   * if uploding files feature is disabled
   */
  enableFiles = () => {
    this.context.FeatureRestriction.isFilesEnabled()
      .then((response) => {
        if (response !== this.state.enableFiles) {
          this.setState({ enableFiles: response });
        }
      })
      .catch((error) => {
        if (this.state.enableFiles !== false) {
          this.setState({ enableFiles: false });
        }
      });
  };

  /**
   * if sending emojis feature is disabled
   */
  enableEmojis = () => {
    this.context.FeatureRestriction.isEmojisEnabled()
      .then((response) => {
        if (response !== this.state.enableEmojis) {
          this.setState({ enableEmojis: response });
        }
      })
      .catch((error) => {
        if (this.state.enableEmojis !== false) {
          this.setState({ enableEmojis: false });
        }
      });
  };

  /**
   * if sharing collborative document feature is disabled
   */
  enableCollaborativeDocument = () => {
    this.context.FeatureRestriction.isCollaborativeDocumentEnabled()
      .then((response) => {
        if (response !== this.state.enableCollaborativeDocument) {
          this.setState({ enableCollaborativeDocument: response });
        }
      })
      .catch((error) => {
        if (this.state.enableCollaborativeDocument !== false) {
          this.setState({ enableCollaborativeDocument: false });
        }
      });
  };

  /**
   * if sharing collborative whiteboard feature is disabled
   */
  enableCollaborativeWhiteboard = () => {
    this.context.FeatureRestriction.isCollaborativeWhiteBoardEnabled()
      .then((response) => {
        if (response !== this.state.enableCollaborativeWhiteboard) {
          this.setState({ enableCollaborativeWhiteboard: response });
        }
      })
      .catch((error) => {
        if (this.state.enableCollaborativeWhiteboard !== false) {
          this.setState({ enableCollaborativeWhiteboard: false });
        }
      });
  };

  focusOnMessageComposer = () => {
    if (this.messageInputRef && this.messageInputRef.current) {
      this.messageInputRef.current.focus();
      this.updateSelection();
    }
  };

  /**
   * Updates caret selection object
   */
  updateSelection() {
    try {
      if (this.chatWindow.getSelection) {
        this.sel = this.chatWindow.getSelection();
        if (this.sel.getRangeAt && this.sel.rangeCount) {
          this.range = this.sel.getRangeAt(0);
        }
      }
    } catch (error) {
      console.log("Error updating selection", error);
    }
  }

  /**
   * Pastes given html at caret position
   */
  // pasteHtmlAtCaret(html) {
  //   try {
  //     if (this.sel && this.range) {
  //       this.range.deleteContents();

  //       let el = document.createElement("div");
  //       el.innerHTML = html;
  //       let frag = document.createDocumentFragment(),
  //         node,
  //         lastNode;
  //       while ((node = el.firstChild)) {
  //         lastNode = frag.appendChild(node);
  //       }
  //       this.range.insertNode(frag);

  //       if (lastNode) {
  //         this.range = this.range.cloneRange();
  //         this.range.setStartAfter(lastNode);
  //         this.range.collapse(true);
  //         this.sel.removeAllRanges();
  //         this.sel.addRange(this.range);
  //       }
  //     } else if (document.selection && document.selection.type != "Control") {
  //       document.selection.createRange().pasteHTML(html);
  //     }
  //   } catch (error) {
  //     console.log("Error pasting html to caret", error);
  //   }
  // }

  emojiClicked = (emoji) => {
    if (this.state.messageToReact) {
      this.reactToMessages(emoji);
      return;
    }
  
    const element = this.messageInputRef.current; 
    this.focusOnMessageComposer();
    const { cursorPosition } = this.state; 
    const selectionStart = cursorPosition?.selectionStart || element.selectionStart;
    const selectionEnd = cursorPosition?.selectionEnd || element.selectionEnd;
    const currentText = this.state.messageInput;
    const newText =
      currentText.substring(0, selectionStart) + 
      emoji.char + 
      currentText.substring(selectionEnd); 

    this.setState(
      {
        messageInput: newText,
        messageType: "text",
        isEmojiEntered: true,
        showRecorderdiv: false,
      },
      () => {
        const newCursorPosition = selectionStart + emoji.char.length;
        element.setSelectionRange(newCursorPosition, newCursorPosition);
        element.focus(); 
      }
    );
  };
  
  adjustHeight = (textarea) => {
    if (!this.state.originalHeight) {
      this.setState({ originalHeight: textarea.clientHeight });
    }

    textarea.style.overflow = "auto";
    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 85);
    textarea.style.height = newHeight <= 21 ? "21px" : `${newHeight}px`;
  };
  changeHandler = (event) => {
    this.startTyping();

    const textarea = event.target;
    let messageInput = textarea.value;

    this.setState({
      messageInput,
      messageType: "text",
      showRecorderdiv: !messageInput.length,
      isEmojiEntered: false,
    });

    if (messageInput.length === 0) {
      // Reset to original height if input is erased
      textarea.style.height = `${this.state.originalHeight}px`;
    } else if (this.state.lineCount > 1) {
      this.adjustHeight(textarea); // Adjust height on input change
    } else if (event.keyCode == 13) {
      this.adjustHeight(textarea); // Adjust height on input change
    }
    if (!this.hasEventFired) {
      this.gtagAnalyticsOninput();
    }
  };

  
  countLines = () => {
    const textarea = this.messageInputRef.current;
    if (textarea) {
      // Create a temporary element to measure the line height
      const lineHeight = parseInt(
        window.getComputedStyle(textarea).lineHeight,
        10
      );
      if (lineHeight === 0) return 0; // Default to 0 if lineHeight is not set

      // Calculate number of lines
      const lines = Math.floor(textarea.scrollHeight / lineHeight);
      this.setState({ lineCount: lines });
    }
  };

  toggleFilePicker = () => {
    const currentState = !this.state.showFilePicker;
    this.setState({ showFilePicker: currentState });
    this.setState({ emojiViewer: false, stickerViewer: false });

    // Select all elements with the class name "filelist__item"
    const filelistItems = document.querySelectorAll(".filelist__item");

    // Set tabindex to 0 if the file picker is open, else set it to -1
    const newTabIndex = currentState ? "0" : "-1";

    // Set the new tabindex for all selected elements
    filelistItems.forEach((item) => {
      item.setAttribute("tabindex", newTabIndex);
    });
  };

  openFileDialogue = (fileType) => {
    switch (fileType) {
      case "image":
        this.imageUploaderRef.current.click();
        break;
      case "file":
        this.fileUploaderRef.current.click();
        break;
      case "audio":
        this.audioUploaderRef.current.click();
        break;
      case "video":
        this.videoUploaderRef.current.click();
        break;
      default:
        break;
    }
  };

  onImageChange = (e) => {
    if (!this.imageUploaderRef.current.files["0"]) {
      return false;
    }

    const uploadedFile = this.imageUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      (event) => {
        // Setting up base64 URL on image

        const newFile = new File(
          [reader.result],
          uploadedFile.name,
          uploadedFile
        );
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.IMAGE);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onFileChange = (e) => {
    if (!this.fileUploaderRef.current.files["0"]) {
      return false;
    }

    const uploadedFile = this.fileUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      (event) => {
        // Setting up base64 URL on image

        const newFile = new File(
          [reader.result],
          uploadedFile.name,
          uploadedFile
        );
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.FILE);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onAudioChange = (e) => {
    if (!this.audioUploaderRef.current.files["0"]) {
      return false;
    }

    const uploadedFile = this.audioUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      () => {
        // Setting up base64 URL on image

        const newFile = new File(
          [reader.result],
          uploadedFile.name,
          uploadedFile
        );
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.AUDIO);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onVideoChange = (e) => {
    if (!this.videoUploaderRef.current.files["0"]) {
      return false;
    }

    const uploadedFile = this.videoUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      () => {
        // Setting up base64 URL on image

        const newFile = new File(
          [reader.result],
          uploadedFile.name,
          uploadedFile
        );
        this.sendMediaMessage(newFile, CometChat.MESSAGE_TYPE.VIDEO);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  getReceiverDetails = () => {
    let receiverId;
    let receiverType;

    if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER) {
      receiverId = this.context.item.uid;
      receiverType = CometChat.RECEIVER_TYPE.USER;
    } else if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      receiverId = this.context.item.guid;
      receiverType = CometChat.RECEIVER_TYPE.GROUP;
    }

    return { receiverId: receiverId, receiverType: receiverType };
  };

  sendMediaMessage = (messageInput, messageType) => {
    this.toggleFilePicker();
    this.endTyping(null, null);
    const { receiverId, receiverType } = this.getReceiverDetails();

    let mediaMessage = new CometChat.MediaMessage(
      receiverId,
      messageInput,
      messageType,
      receiverType
    );
    if (this.props.parentMessageId) {
      mediaMessage.setParentMessageId(this.props.parentMessageId);
    }

    mediaMessage.setSender(this.loggedInUser);
    mediaMessage.setReceiver(this.context.type);
    mediaMessage.setType(messageType);
    mediaMessage.setMetadata({
      [enums.CONSTANTS["FILE_METADATA"]]: messageInput,
    });
    mediaMessage._composedAt = getUnixTimestamp();
    mediaMessage._id = ID();

    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);
    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      mediaMessage,
    ]);

    CometChat.sendMessage(mediaMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: mediaMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj,
        ]);
      })
      .catch((error) => {
        const newMessageObj = { ...mediaMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj,
        ]);
      });
  };

  onDocumentClick = (event) => {
    const { emojiViewer, showFilePicker, stickerViewer } = this.state;
    const { composerRef } = this;

    // Define classes to check for inside the composers
    const divClassesToCheck = [
      "emoji__keyboard",
      "input__sticky",
      "button__emoji",
    ];

    // Function to check if click is inside any composer or specific div classes
    const isInsideComposerOrDiv = () => {
      // Check if click is inside the composer
      if (composerRef && composerRef.contains(event.target)) {
        return true;
      }

      // Check if click is inside any of the specified div classes
      return this.isInsideDiv(event.target, divClassesToCheck);
    };

    // Determine if any viewer should be closed
    const shouldCloseViewers =
      (emojiViewer || showFilePicker || stickerViewer) &&
      !isInsideComposerOrDiv();

    // Close viewers if necessary
    if (shouldCloseViewers) {
      this.setState({
        emojiViewer: false,
        stickerViewer: false,
        showFilePicker: false,
      });
    }
  };

  // Function to check if click target or any of its ancestors contain specified classes
  isInsideDiv = (target, divClasses) => {
    while (target) {
      if (
        divClasses.some((className) => target.classList.contains(className))
      ) {
        return true;
      }
      target = target.parentElement;
    }
    return false;
  };
  // sendMessageOnEnter = (event) => {
  //   if (event.keyCode === 13 && !event.shiftKey) {
  //     event.preventDefault();
  //     this.sendTextMessage();
  //     return true;
  //   }
  // };
  sendMessageOnEnter = (event) => {
    const textarea = this.messageInputRef.current;

    if (event.key === "Enter") {
      if (event.shiftKey) {
        // Shift + Enter: Add new line
        event.preventDefault();
        const { selectionStart, selectionEnd } = textarea;
        const value = textarea.value;
        textarea.value =
          value.slice(0, selectionStart) + "\n" + value.slice(selectionEnd);
        textarea.selectionStart = textarea.selectionEnd = selectionStart + 1;
        this.adjustHeight(textarea);
      } else {
        // Enter: Send message
        event.preventDefault();
        this.sendTextMessage(); // Implement your send message logic here

        // Clear the input and adjust height
        this.setState({
          messageInput: "",
          showRecorderdiv: true,
        });
        textarea.value = "";
        textarea.style.height = `${this.state.originalHeight}px`; // Reset to original height
        this.resetTextareaHeight();
      }
    }
  };


  
  sendTextMessage = () => {
    
    this.setState({ messageLength: 1 });

    if (this.state.emojiViewer) {
      this.setState({ emojiViewer: false });
    }

    if (!this.state.messageInput.trim().length) {
      return false;
    }

    if (this.state.messageToBeEdited) {
      this.editMessage();
      return false;
    }

    this.endTyping(null, null);
    // this.setState({ showRecorderdiv: true });
    this.setState({
      showRecorderdiv: true,
      isEmojiEntered: false,
    });
  window.parent.postMessage(
    { action: "socketconnection", manualStatus: true,

     },
    "*"
  );
  localStorage.setItem("manualStatus", "true");
  // Update state and re-enable the socket connection
  this.setState({ manualStatus: true }, () => {
    this.enablestatus();
  });


    let { receiverId, receiverType } = this.getReceiverDetails();
    let messageInput = this.state.messageInput.trim();

    let textMessage = new CometChat.TextMessage(
      receiverId,
      messageInput,
      receiverType
    );
    if (this.props.parentMessageId) {
      textMessage.setParentMessageId(this.props.parentMessageId);
    }
    textMessage.setSender(this.loggedInUser);
    textMessage.setReceiver(this.context.type);
    textMessage.setText(messageInput);
    textMessage._composedAt = getUnixTimestamp();
    textMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      textMessage,
    ]);
    this.setState({ messageInput: "", replyPreview: false });

    this.messageInputRef.current.textContent = "";
    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);

    CometChat.sendMessage(textMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: textMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj,
        ]);

        if (
          this.props.isRespondingAsAmbassador === "1" ||
          this.props.isChatingWithSupport
        ) {
          this.sendMail(message);
        }

        // Call gtagAnalyticsMsgSent on successful message send
        this.gtagAnalyticsMsgSent();
        this.hasEventFired = false;
      })
      .catch((error) => {
        const newMessageObj = { ...textMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj,
        ]);

        if (
          error &&
          error.hasOwnProperty("code") &&
          error.code === "ERR_GUID_NOT_FOUND"
        ) {
          //this.context.setDeletedGroupId(this.context.item.guid);
        }
      });
    this.gtagAnalyticsMsgSent();
  };

  editMessage = () => {
    this.endTyping(null, null);

    const messageToBeEdited = this.props.messageToBeEdited;

    let { receiverId, receiverType } = this.getReceiverDetails();
    let messageText = this.state.messageInput ? this.state.messageInput.trim() : "";
    
    let textMessage = new CometChat.TextMessage(
      receiverId,
      messageText,
      receiverType
    );
    textMessage.setId(messageToBeEdited.id);

    const newMessage = Object.assign({}, textMessage, {
      messageFrom: messageToBeEdited.messageFrom,
    });
    this.props.actionGenerated(enums.ACTIONS["MESSAGE_EDITED"], newMessage);

    this.setState(
      { messageInput: "", showRecorderdiv: true, isEmojiEntered: false },
      () => {
        // console.log("showRecorderdiv", this.state.showRecorderdiv);
        this.messageInputRef.current.textContent = "";
        this.resetTextareaHeight();
      }
    );
    this.messageInputRef.current.textContent = "";
    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);

    this.closeEditPreview();

    CometChat.editMessage(textMessage)
      .then((message) => {
        
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_EDITED"], {
          ...message,
        });
        this.setState({ messageToBeEdited: "" });
      })
      .catch((error) =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  closeEditPreview = () => {
    this.props.actionGenerated(enums.ACTIONS["CLEAR_EDIT_PREVIEW"]);
    const textarea = this.messageInputRef.current;

    if (textarea) {
      textarea.value = "";
      textarea.style.height = `${this.state.originalHeight}px`; 
    }
    // this.setState({
    //   messageInput: "",  
    //   showRecorderdiv: true, 
    //   isEmojiEntered: false, 
    // });
  };

  resetTextareaHeight = () => {
    const textarea = this.messageInputRef.current;
    if (textarea) {
      textarea.style.height = `${this.state.originalHeight || 21}px`;
    }
  };
  

  startTyping = (timer, metadata) => {
    let typingInterval = timer || 5000;

    //if typing indicator feature is disabled
    if (this.state.enableTypingIndicator === false) {
      return false;
    }

    if (this.isTyping) {
      return false;
    }

    let { receiverId, receiverType } = this.getReceiverDetails();
    let typingMetadata = metadata || undefined;

    let typingNotification = new CometChat.TypingIndicator(
      receiverId,
      receiverType,
      typingMetadata
    );
    CometChat.startTyping(typingNotification);

    this.isTyping = setTimeout(() => {
      this.endTyping(null, typingMetadata);
    }, typingInterval);
  };

  endTyping = (event, metadata) => {
    this.updateSelection(); //updating selection onblur
    //fixing synthetic issue
    if (event) {
      event.persist();
    }

    //if typing indicator is disabled for chat wigdet in dashboard
    if (this.state.enableTypingIndicator === false) {
      return false;
    }

    let { receiverId, receiverType } = this.getReceiverDetails();

    let typingMetadata = metadata || undefined;

    let typingNotification = new CometChat.TypingIndicator(
      receiverId,
      receiverType,
      typingMetadata
    );
    CometChat.endTyping(typingNotification);

    clearTimeout(this.isTyping);
    this.isTyping = null;
  };

  toggleStickerPicker = () => {
    //console.log("caleed stiker")
    const stickerViewer = this.state.stickerViewer;
    this.setState({
      stickerViewer: !stickerViewer,
      emojiViewer: false,
      showFilePicker: false,
    });
  };

  toggleEmojiPicker = () => {
    const element = this.messageInputRef.current;
  
    if (element) {
      const cursorPosition = {
        selectionStart: element.selectionStart,
        selectionEnd: element.selectionEnd,
      };
  
      this.setState({
        cursorPosition, 
        emojiViewer: !this.state.emojiViewer,
        stickerViewer: false,
        showFilePicker: false,
      });
    } else {
      this.setState({
        emojiViewer: !this.state.emojiViewer,
        stickerViewer: false,
        showFilePicker: false,
      });
    }
  };
  
  toggleCreatePoll = () => {
    const createPoll = this.state.createPoll;
    this.setState({ createPoll: !createPoll });
  };


  
  enablestatus = () => {
    const Mode=getDataFromParams("Mode")
    const appID = COMETCHAT_CONSTANTS[Mode].APP_ID; 
    const manualStatus = this.state.manualStatus;
    const socket = new CometChat.AppSettingsBuilder()
      .autoEstablishSocketConnection(manualStatus)
      .build();
    
    CometChat.init(appID, socket);
  };
  




  toggleCollaborativeDocument = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();
    CometChat.callExtension("document", "POST", "v1/create", {
      receiver: receiverId,
      receiverType: receiverType,
    })
      .then((response) => {
        // Response with document url
        if (response && response.hasOwnProperty("document_url")) {
          this.context.setToastMessage("success", "DOCUMENT_SUCCESS");
        } else {
          this.context.setToastMessage("error", "DOCUMENT_FAIL");
        }
      })
      .catch((error) =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  toggleCollaborativeBoard = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();
    CometChat.callExtension("whiteboard", "POST", "v1/create", {
      receiver: receiverId,
      receiverType: receiverType,
    })
      .then((response) => {
        // Response with board_url
        if (response && response.hasOwnProperty("board_url")) {
          this.context.setToastMessage("success", "WHITEBOARD_SUCCESS");
        } else {
          this.context.setToastMessage("error", "WHITEBOARD_FAIL");
        }
      })
      .catch((error) =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  closeCreatePoll = () => {
    this.toggleCreatePoll();
    this.toggleFilePicker();
  };

  actionHandler = (action, message) => {
    switch (action) {
      case enums.ACTIONS["POLL_CREATED"]:
        this.toggleCreatePoll();
        this.toggleFilePicker();
        break;
      case enums.ACTIONS["SEND_STICKER"]:
        this.sendSticker(message);
        break;
      case enums.ACTIONS["CLOSE_STICKER_KEYBOARD"]:
        this.toggleStickerPicker();
        break;
      default:
        break;
    }
  };

  sendSticker = (stickerMessage) => {
    const { receiverId, receiverType } = this.getReceiverDetails();

    const customData = {
      sticker_url: stickerMessage.stickerUrl,
      sticker_name: stickerMessage.stickerName,
    };
    const customType = enums.CUSTOM_TYPE_STICKER;

    const customMessage = new CometChat.CustomMessage(
      receiverId,
      receiverType,
      customType,
      customData
    );
    if (this.props.parentMessageId) {
      customMessage.setParentMessageId(this.props.parentMessageId);
    }
    customMessage.setSender(this.loggedInUser);
    customMessage.setReceiver(this.context.type);
    customMessage.setMetadata({ incrementUnreadCount: true });
    customMessage._composedAt = getUnixTimestamp();
    customMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      customMessage,
    ]);
    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);

    CometChat.sendCustomMessage(customMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: customMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj,
        ]);
      })
      .catch((error) => {
        const newMessageObj = { ...customMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj,
        ]);
      });
  };

  sendReplyMessage = (messageInput) => {
    let { receiverId, receiverType } = this.getReceiverDetails();

    let textMessage = new CometChat.TextMessage(
      receiverId,
      messageInput,
      receiverType
    );
    if (this.props.parentMessageId) {
      textMessage.setParentMessageId(this.props.parentMessageId);
    }
    textMessage.setSender(this.loggedInUser);
    textMessage.setReceiver(this.context.type);
    textMessage._composedAt = getUnixTimestamp();
    textMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [
      textMessage,
    ]);

    SoundManager.play(enums.CONSTANTS.AUDIO["OUTGOING_MESSAGE"], this.context);
    this.setState({ replyPreview: null });

    CometChat.sendMessage(textMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: textMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], [
          newMessageObj,
        ]);
      })
      .catch((error) => {
        const newMessageObj = { ...textMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], [
          newMessageObj,
        ]);
      });
  };

  clearReplyPreview = () => {
    this.setState({ replyPreview: null });
  };

  startLiveReaction = (event) => {
    //if a live reaction is already in progress, return
    if (this.animationInProgress === true) {
      return false;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    //fetch the interval from the constants
    const liveReactionInterval = enums.CONSTANTS["LIVE_REACTION_INTERVAL"];

    //mount the live reaction component
    this.props.actionGenerated(enums.ACTIONS["SEND_LIVE_REACTION"]);
    this.sendTransientMessage();

    //set the timer to stop the live reaction
    this.timeout = setTimeout(this.stopLiveReaction, liveReactionInterval);
  };

  stopLiveReaction = () => {
    //unmount the live reaction component
    this.props.actionGenerated(enums.ACTIONS["STOP_LIVE_REACTION"]);

    //set the animation flag to false
    this.animationInProgress = false;
  };

  sendTransientMessage = () => {
    //fetching the metadata type from constants
    const metadata = {
      type: enums.CONSTANTS["METADATA_TYPE_LIVEREACTION"],
      reaction: this.props.reaction,
    };

    const receiverType =
      this?.context?.type === CometChat.ACTION_TYPE.TYPE_USER
        ? CometChat.ACTION_TYPE.TYPE_USER
        : CometChat.ACTION_TYPE.TYPE_GROUP;
    const receiverId =
      this?.context?.type === CometChat.ACTION_TYPE.TYPE_USER
        ? this?.context?.item?.uid
        : this?.context?.item?.guid;

    let transientMessage = new CometChat.TransientMessage(
      receiverId,
      receiverType,
      metadata
    );
    CometChat.sendTransientMessage(transientMessage);
  };

  reactToMessages = (emoji) => {
    //close the emoji keyboard
    this.toggleEmojiPicker();

    //message object data structure
    let messageObject = { ...this.state.messageToReact };
    let newMessageObject = {};
    let reactionObject = {};

    const userObject = {};
    if (this.loggedInUser.avatar && this.loggedInUser.avatar.length) {
      userObject["name"] = this.loggedInUser.name;
      userObject["avatar"] = this.loggedInUser.avatar;
    } else {
      userObject["name"] = this.loggedInUser.name;
    }

    const emojiObject = {
      [emoji.char]: { [this.loggedInUser.uid]: userObject },
    };

    const reactionExtensionsData = checkMessageForExtensionsData(
      messageObject,
      "reactions"
    );
    //if the message object has reactions extension data in metadata
    if (reactionExtensionsData) {
      //if the reactions metadata has the selected emoji/reaction
      if (reactionExtensionsData[emoji.char]) {
        //if the reactions metadata has the selected emoji/reaction for the loggedin user
        if (reactionExtensionsData[emoji.char][this.loggedInUser.uid]) {
          reactionObject = {
            ...messageObject["metadata"]["@injected"]["extensions"][
              "reactions"
            ],
          };
          delete reactionObject[emoji.char][this.loggedInUser.uid];
        } else {
          reactionObject = {
            ...messageObject["metadata"]["@injected"]["extensions"][
              "reactions"
            ],
            [emoji.char]: {
              ...messageObject["metadata"]["@injected"]["extensions"][
                "reactions"
              ][emoji.char],
              [this.loggedInUser.uid]: userObject,
            },
          };
        }
      } else {
        reactionObject = {
          ...messageObject["metadata"]["@injected"]["extensions"]["reactions"],
          ...emojiObject,
        };
      }
    } else {
      if (messageObject.hasOwnProperty("metadata") === false) {
        messageObject["metadata"] = {};
      }

      if (messageObject["metadata"].hasOwnProperty("@injected") === false) {
        messageObject["metadata"]["@injected"] = {};
      }

      if (
        messageObject["metadata"]["@injected"].hasOwnProperty("extensions") ===
        false
      ) {
        messageObject["metadata"]["@injected"]["extensions"] = {};
      }

      if (
        messageObject["metadata"]["@injected"]["extensions"].hasOwnProperty(
          "reactions"
        ) === false
      ) {
        messageObject["metadata"]["@injected"]["extensions"]["reactions"] = {};
      }

      reactionObject = {
        ...emojiObject,
      };
    }

    const metadatObject = {
      metadata: {
        ...messageObject["metadata"],
        "@injected": {
          ...messageObject["metadata"]["@injected"],
          extensions: {
            ...messageObject["metadata"]["@injected"]["extensions"],
            reactions: {
              ...reactionObject,
            },
          },
        },
      },
    };

    newMessageObject = {
      ...messageObject,
      data: {
        ...messageObject,
        ...metadatObject,
      },
      ...metadatObject,
    };

    this.props.actionGenerated(
      enums.ACTIONS["MESSAGE_EDITED"],
      newMessageObject
    );

    CometChat.callExtension("reactions", "POST", "v1/react", {
      msgId: this.state.messageToReact.id,
      emoji: emoji.char,
    })
      .then((response) => {
        // Reaction failed
        if (!response || !response.success || response.success !== true) {
          this.props.actionGenerated(
            enums.ACTIONS["ERROR"],
            [],
            "SOMETHING_WRONG"
          );
        }
      })
      .catch((error) =>
        this.props.actionGenerated(
          enums.ACTIONS["ERROR"],
          [],
          "SOMETHING_WRONG"
        )
      );
  };

  clearAudioRecordedData = () => {
    clearInterval(this.state.interval);
    this.setState({
      audioName: "",
      audioBlobUrl: "",
      recordingTime: "00:00",
      audioRecordedTime: "",
      recorder: null,
      startTime: null,
      isPlaying: false,
      recordedBlob: null,
      showAudioSendBtn: false,
      isAudioRecording: false,
      isShowRecorderIcon: true,
    });

    if (this.state.stream) {
      this.state.stream.getAudioTracks().forEach((track) => track.stop());
      this.setState({ stream: null });
    }
  };

  startRecording = () => {
    this.setState({ isShowRecorderIcon: false });

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia is not supported in this browser");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.setState({ stream });
        const recorder = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/mp3",
          recorderType: RecordRTC.StereoAudioRecorder,
        });
        recorder.startRecording();
        const startTime = moment();
        const interval = setInterval(() => {
          const currentTime = moment();
          const diffTime = moment.duration(currentTime.diff(startTime));
          const time =
            this.toString(diffTime.minutes()) +
            ":" +
            this.toString(diffTime.seconds());
          this.setState({
            recorder: recorder,
            startTime: startTime,
            interval: interval,
            recordingTime: time,
          });
        }, 500);
      })
      .catch((error) => {
        this.setState({ isAudioRecording: false });
        console.error("Error accessing microphone:", error);
      });
  };

  stopAudioRecording = () => {
    if (this.state.isAudioRecording && this.state.recorder) {
      this.state.recorder.stopRecording((blob) => {
        clearInterval(this.state.interval);

        if (this.state.startTime) {
          const mp3Name = encodeURIComponent(
            "audio_" + new Date().getTime() + ".mp3"
          );

          this.setState({
            recordedBlob: {
              blob: this.state.recorder.getBlob(),
              title: mp3Name,
            },
            recorder: null,
            startTime: null,
            isAudioRecording: false,
            showAudioSendBtn: true,
          });

          if (this.state.stream) {
            this.state.stream.getAudioTracks().forEach((track) => track.stop());
            this.setState({ stream: null });
          }
        }
      });
    } else {
      console.log("No active recording to stop.");
    }
  };

  sendRecordedData = () => {
    if (this.state.recordedBlob) {
      const blob = new Blob([this.state.recordedBlob.blob], {
        type: "audio/mp3",
      });

      this.sendMediaMessage(blob, CometChat.MESSAGE_TYPE.AUDIO);
    } else {
      console.error(
        "this.state.recordedBlob is null. Unable to send audio data."
      );
    }

    this.setState({ showFilePicker: false });
    this.setState({ isAudioStopped: false });
    this.setState({ showAudioSendBtn: false });
    this.setState({ isShowRecorderIcon: true });
    this.clearAudioRecordedData();
  };

  toString = (value) => {
    let val = value;
    if (!value) {
      val = "00";
    }
    if (value < 10) {
      val = "0" + value;
    }
    return val;
  };

  sendMail = (message) => {
    let apiUrl = "";
    const univId = getDataFromParams("univ");

    if (window.location.host.includes("truleague-admin.test")) {
      apiUrl = "http://localhost:8088/api/chat/cometChatEmailNotificationUniv";
    } else if (window.location.host.includes("staging.chat.truleague.com")) {
      apiUrl =
        "https://api.staging.truleague.com/api/chat/cometChatEmailNotificationUniv";
    } else if (window.location.host.includes("chat.truleague.com")) {
      apiUrl =
        "https://portal.api.truleague.com/api/chat/cometChatEmailNotificationUniv";
    }

    const requestData = {
      sender_uid: message.sender.uid || "",
      receiver_uid: message.receiverId || "",
      college_uid: univId || "",
      message_text: message?.text || "",
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Unable to send mail, please try again.");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error making POST request for sendMail:", error);
      });
  };
  waitForGtagInitialization = () => {
    return new Promise((resolve) => {
      const checkGtag = setInterval(() => {
        if (typeof gtag === "function") {
          clearInterval(checkGtag);
          resolve();
        }
      }, 200);
    });
  };

  gtagAnalyticsOninput = async () => {
    const UserTags = getDataFromParams("setUserTags");
    if (UserTags === "ambassadors") {
      await this.waitForGtagInitialization();
      try {
        // console.log('ChatInput_To_Ambassador_Started');
        gtag("event", "ChatInput_To_Ambassador_Started", {
          event_category: "User_Action",
          event_label: "ChatInput_To_Ambassador_Started",
        });
      } catch (error) {
        console.error("Error sending gtag event:", error);
      }
    }
  };

  gtagAnalyticsMsgSent = async () => {
    const UserTags = getDataFromParams("setUserTags");
    if (UserTags === "ambassadors") {
      await this.waitForGtagInitialization();
      try {
        // console.log('Chat_To_Ambassador_Message_Sent');
        gtag("event", "Chat_To_Ambassador_Message_Sent", {
          event_category: "User_Action",
          event_label: "Chat_To_Ambassador_Message_Sent",
        });
      } catch (error) {
        console.error("Error sending gtag event:", error);
      }
    }
  };

  gtagAnalyticsOninput = () => {
    const UserTags = getDataFromParams("setUserTags");

    if (UserTags === "ambassadors" && !this.hasEventFired) {
      try {
        gtag("event", "ChatInput_To_Ambassador_Started", {
          event_category: "User_Action",
          event_label: "ChatInput_To_Ambassador_Started",
        });
        this.hasEventFired = true;
      } catch (error) {
        // console.log('Error sending gtag event:', error);
      }
    }
  };
  gtagAnalyticsMsgSent = () => {
    const UserTags = getDataFromParams("setUserTags");

    if (UserTags === "ambassadors") {
      try {
        gtag("event", "Chat_To_Ambassador_Message_Sent", {
          event_category: "User_Action",
          event_label: "Chat_To_Ambassador_Message_Sent",
        });
      } catch (error) {
        // console.log('Error sending gtag event:', error);
      }
    }
  };

  render() {
    let liveReactionBtn = null;
    const liveReactionText = Translator.translate(
      "LIVE_REACTION",
      this.context.language
    );
    if (enums.CONSTANTS["LIVE_REACTIONS"].hasOwnProperty(this.props.reaction)) {
      const reactionName = this.props.reaction;
      liveReactionBtn = (
        <div
          title={liveReactionText}
          css={reactionBtnStyle()}
          className="button__reactions"
          onClick={this.startLiveReaction}
        >
          <img src={heartIcon} alt={reactionName} />
        </div>
      );
    }

    let disabledState = false;
    if (this.context.item.blockedByMe) {
      disabledState = true;
    }

    const docText = Translator.translate("ATTACH_FILE", this.context.language);
    let docs = (
      <div
        title={docText}
        css={fileItemStyle(docIcon, this.context)}
        className="filelist__item item__file"
        onClick={() => {
          this.openFileDialogue("file");
        }}
        role="button"
      >
        <div className="fileIcons">
          <i></i>
        </div>{" "}
        <div>Document</div>
        <input
          onChange={this.onFileChange}
          type="file"
          id="file"
          ref={this.fileUploaderRef}
          tabIndex={-1}
          aria-hidden="true"
        />
      </div>
    );

    const videoText = Translator.translate(
      "ATTACH_VIDEO",
      this.context.language
    );
    const audioText = Translator.translate(
      "ATTACH_AUDIO",
      this.context.language
    );
    const imageText = Translator.translate(
      "ATTACH_IMAGE",
      this.context.language
    );
    let avp = (
      <React.Fragment>
        <div
          title={videoText}
          css={fileItemStyle(videoIcon, this.context)}
          className="filelist__item item__video"
          onClick={() => {
            this.openFileDialogue("video");
          }}
          role="button"
        >
          <div className="fileIcons">
            <i></i>
          </div>
          <div>Video</div>
          <input
            onChange={this.onVideoChange}
            accept="video/*"
            type="file"
            ref={this.videoUploaderRef}
            tabIndex={-1}
            aria-hidden="true"
          />
        </div>
        <div className="commet-divider"></div>
        <div
          title={audioText}
          css={fileItemStyle(audioIcon, this.context)}
          className="filelist__item item__audio"
          onClick={() => {
            this.openFileDialogue("audio");
          }}
          role="button"
        >
          <div className="fileIcons">
            <i></i>
          </div>{" "}
          <div>Audio</div>
          <input
            onChange={this.onAudioChange}
            accept="audio/*"
            type="file"
            ref={this.audioUploaderRef}
            tabIndex={-1}
            aria-hidden="true"
          />
        </div>
        <div className="commet-divider"></div>
        <div
          title={imageText}
          css={fileItemStyle(imageIcon, this.context)}
          className="filelist__item item__image"
          onClick={() => {
            this.openFileDialogue("image");
          }}
          role="button"
        >
          <div className="fileIcons">
            <i></i>
          </div>{" "}
          <div>Photos</div>
          <input
            onChange={this.onImageChange}
            accept="image/*"
            type="file"
            ref={this.imageUploaderRef}
            tabIndex={-1}
            aria-hidden="true"
          />
        </div>
        <div className="commet-divider"></div>
      </React.Fragment>
    );

    const pollText = Translator.translate("CREATE_POLL", this.context.language);
    let createPollBtn = (
      <div
        title={pollText}
        css={fileItemStyle(pollIcon, this.context)}
        className="filelist__item item__poll"
        onClick={this.toggleCreatePoll}
        role="button"
      >
        <div className="fileIcons">
          <i></i>
        </div>{" "}
        <div>Create Poll</div>
      </div>
    );

    const collaborativeDocText = Translator.translate(
      "COLLABORATE_USING_DOCUMENT",
      this.context.language
    );
    let collaborativeDocBtn = (
      <div
        title={collaborativeDocText}
        css={fileItemStyle(documentIcon, this.context)}
        className="filelist__item item__document"
        onClick={this.toggleCollaborativeDocument}
        role="button"
      >
        <i></i>
      </div>
    );

    const collaborativeBoardText = Translator.translate(
      "COLLABORATE_USING_WHITEBOARD",
      this.context.language
    );
    let collaborativeBoardBtn = (
      <div
        title={collaborativeBoardText}
        css={fileItemStyle(whiteboardIcon, this.context)}
        className="filelist__item item__whiteboard"
        onClick={this.toggleCollaborativeBoard}
        role="button"
      >
        <i></i>
      </div>
    );

    const emojiText = Translator.translate("EMOJI", this.context.language);
    let emojiBtn = (
      <div
        title={emojiText}
        css={emojiButtonStyle(insertEmoticon, this.context)}
        className="button__emoji"
        onClick={() => {
          this.toggleEmojiPicker();
          this.setState({ messageToReact: "" });
        }}
        tabIndex={0}
        aria-label="Add Emoji"
        role="button"
      >
        <div css={attachstyle(this.state.emojiViewer)}>
          <i></i>
        </div>
      </div>
    );

    const recorderText = Translator.translate(
      "RECORD_AUDIO",
      this.context.language
    );
    let recorderBtn = (
      <div
        title={recorderText}
        css={recorderButtonStyle(micIcon, this.context)}
        className="button__record"
        onClick={() => {
          this.startRecording();
          this.setState({ isAudioRecording: true });
        }}
        tabIndex={0}
        aria-label="Record audio"
        role="button"
      >
        <i></i>
      </div>
    );
    let stopBtn = (
      <div
        title="Stop recording"
        css={stopButtonStyle(pauseIcon, this.context)}
        className="button__stop"
        onClick={() => {
          this.stopAudioRecording();
        }}
      >
        <i></i>
      </div>
    );
    let deleteBtn = (
      <div
        title="Delete recording"
        css={deleteButtonStyle(clearIcon, this.context)}
        className="button__delete"
        onClick={() => {
          this.clearAudioRecordedData();
        }}
      >
        <i></i>
      </div>
    );
    let recordingTimer = (
      <div css={timerStyle(this.context)} className="recording__timer">
        {this.state.recordingTime}
      </div>
    );

    const StickerText = Translator.translate("STICKER", this.context.language);
    let stickerBtn = (
      <div
        title={StickerText}
        css={stickerBtnStyle(stickerIcon, this.context)}
        className="button__sticker"
        onClick={this.toggleStickerPicker}
        tabIndex={0}
        aria-label="Sticker"
      >
        <div css={attachstyle(this.state.stickerViewer)}>
          <i></i>
        </div>
      </div>
    );

    const sendMessageText = Translator.translate(
      "SEND_MESSAGE",
      this.context.language
    );
    let sendBtn = (
      <div
        title={sendMessageText}
        css={sendButtonStyle(sendBlue, this.context)}
        className="button__send"
        onClick={() => {
          this.setState({ showRecorderdiv: false });
          this.sendTextMessage();
        }}
        tabIndex={0}
        aria-label="Send message"
      >
        <i></i>
      </div>
    );
    let sendBtn2 = (
      <div
        title={sendMessageText}
        css={sendButtonStyle(sendBlue, this.context)}
        className="button__send sendbtn2"
        onClick={this.sendRecordedData}
        tabIndex={0}
        aria-label="Send message"
      >
        <i></i>
      </div>
    );

    //if uploading photos, videos feature is disabled
    if (this.state.enablePhotosVideos === false) {
      avp = null;
    }

    //if files upload are disabled for chat widget in dashboard
    if (this.state.enableFiles === false) {
      docs = null;
    }

    //if polls feature is disabled
    if (this.state.enablePolls === false || this.props.parentMessageId) {
      createPollBtn = null;
    }

    //if collaborative_document are disabled for chat widget in dashboard
    if (
      this.state.enableCollaborativeDocument === false ||
      this.props.parentMessageId
    ) {
      collaborativeDocBtn = null;
    }

    //if collaborative_document are disabled for chat widget in dashboard
    if (
      this.state.enableCollaborativeWhiteboard === false ||
      this.props.parentMessageId
    ) {
      collaborativeBoardBtn = null;
    }

    //if emojis are disabled for chat widget in dashboard
    if (this.state.enableEmojis === false) {
      emojiBtn = null;
    }

    //if live reactions is disabled for chat widget in dashboard
    if (
      this.state.enableLiveReaction === false ||
      this.state.messageInput.length ||
      this.props.parentMessageId
    ) {
      liveReactionBtn = null;
    }

    //if stickers is disabled for chat widget in dashboard
    if (this.state.enableStickers === false) {
      stickerBtn = null;
    }

    if (!this.state.messageInput) {
      sendBtn = null;
    }

    const attachText = Translator.translate("ATTACH", this.context.language);
    let attach = (
      <div css={stickyAttachmentStyle()} className="input__sticky__attachment">
        <div
          css={stickyAttachButtonStyle(roundedPlus, this.context)}
          className="attachment__icon"
          onClick={this.toggleFilePicker}
          title={attachText}
          tabIndex={0}
          aria-label="Attachment"
          role="button"
        >
          <div css={attachstyle(this.state.showFilePicker)}>
            <i></i>
          </div>
        </div>
        <div
          css={filePickerStyle(this.state)}
          className="attachment__filepicker"
          dir={Translator.getDirection(this.context.language)}
        >
          <div css={fileListStyle()} className="filepicker__filelist">
            {avp}
            {docs}
            <div className="commet-divider"></div>
            {/* {createPollBtn} */}
            <div className="commet-divider"></div>
            {collaborativeDocBtn}
            {collaborativeBoardBtn}
          </div>
        </div>
      </div>
    );

    if (
      avp === null &&
      docs === null &&
      createPollBtn === null &&
      collaborativeDocBtn === null &&
      collaborativeBoardBtn === null
    ) {
      attach = null;
    }

    let createPoll = null;
    if (this.state.createPoll) {
      createPoll = (
        <CometChatCreatePoll
          close={this.closeCreatePoll}
          actionGenerated={this.actionHandler}
        />
      );
    }

    let editPreview = null;
    if (this.state.messageToBeEdited) {
      let messageText = this.state.messageToBeEdited;

      //xss extensions data
      const xssData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "xss-filter"
      );
      if (
        xssData &&
        xssData.hasOwnProperty("sanitized_text") &&
        xssData.hasOwnProperty("hasXSS") &&
        xssData.hasXSS === "yes"
      ) {
        messageText = xssData.sanitized_text;
      }

      //datamasking extensions data
      const maskedData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "data-masking"
      );
      if (
        maskedData &&
        maskedData.hasOwnProperty("data") &&
        maskedData.data.hasOwnProperty("sensitive_data") &&
        maskedData.data.hasOwnProperty("message_masked") &&
        maskedData.data.sensitive_data === "yes"
      ) {
        messageText = maskedData.data.message_masked;
      }

      //profanity extensions data
      const profaneData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "profanity-filter"
      );
      if (
        profaneData &&
        profaneData.hasOwnProperty("profanity") &&
        profaneData.hasOwnProperty("message_clean") &&
        profaneData.profanity === "yes"
      ) {
        messageText = profaneData.message_clean;
      }

      editPreview = (
        <div css={editPreviewContainerStyle(this.context, keyframes)}>
          <div css={previewHeadingStyle()}>
            <div css={previewTextStyle()}>
              {Translator.translate("EDIT_MESSAGE", this.context.language)}
            </div>
            <span
              css={previewCloseStyle(closeIcon, this.context)}
              onClick={this.closeEditPreview}
            ></span>
          </div>
          <div>{messageText}</div>
        </div>
      );
    }

    let smartReplyPreview = null;
    if (this.state.replyPreview) {
      const message = this.state.replyPreview;

      const smartReplyData = checkMessageForExtensionsData(
        message,
        "smart-reply"
      );
      if (smartReplyData && smartReplyData.hasOwnProperty("error") === false) {
        const options = [
          smartReplyData["reply_positive"],
          smartReplyData["reply_neutral"],
          smartReplyData["reply_negative"],
        ];
        smartReplyPreview = (
          <CometChatSmartReplyPreview
            options={options}
            clicked={this.sendReplyMessage}
            close={this.clearReplyPreview}
          />
        );
      }
    }

    let stickerViewer = null;
    if (this.state.stickerViewer) {
      stickerViewer = (
        <CometChatStickerKeyboard actionGenerated={this.actionHandler} />
      );
    }

    let emojiViewer = null;
    if (this.state.emojiViewer) {
      emojiViewer = <CometChatEmojiKeyboard onClick={this.emojiClicked} />;
    }

    return (
      <div
        css={chatComposerStyle(this.context)}
        className="chat__composer"
        // style={{
        //   display: this.props.shouldShowInputBox ? "block" : "none",
        // }}
      >
        {editPreview}
        {smartReplyPreview}
        {stickerViewer}
        {emojiViewer}
        <div css={composerInputStyle()} className="composer__input">
          <div
            css={inputInnerStyle(this.props, this.state, this.context)}
            className="input__inner"
          >
            <textarea
              css={messageInputStyle(disabledState)}
              className="input__message-input"
              placeholder={Translator.translate(
                "ENTER_YOUR_MESSAGE_HERE",
                this.context.language
              )}
              dir={Translator.getDirection(this.context.language)}
              onChange={this.changeHandler}
              onBlur={this.endTyping}
              onFocus={this.gtagAnalyticsOninput}
              onKeyDown={this.sendMessageOnEnter}
              ref={this.messageInputRef}
              tabIndex={0}
              role="textbox"
              aria-label={Translator.translate(
                "ENTER_YOUR_MESSAGE_HERE",
                this.context.language
              )}
              value={this.state.messageInput}
            />    
            <div
              css={inputStickyStyle(disabledState, attach, this.context)}
              className="input__sticky"
              style={{
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {attach}
              <div
                css={stickyButtonStyle(this.state)}
                className="input__sticky__buttons"
                tabIndex={0}
              >
                {stickerBtn}
                {emojiBtn}
                {!this.state.isEmojiEntered && this.state.showRecorderdiv ? (
                  <div>
                    {!this.state.messageToBeEdited &&
                    this.state.isShowRecorderIcon
                      ? recorderBtn
                      : null}
                    {!this.state.isShowRecorderIcon ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {deleteBtn}
                        {recordingTimer}
                        {this.state.showAudioSendBtn && sendBtn2}
                        {!this.state.showAudioSendBtn && stopBtn}
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {liveReactionBtn}
              </div>
              {(this.state.messageInput || this.state.isRecording) && sendBtn}
            </div>
          </div>
        </div>

        {createPoll}
      </div>
    );
  }
}

// Specifies the default values for props:
CometChatMessageComposer.defaultProps = {
  theme: theme,
  reaction: "heart",
};

CometChatMessageComposer.propTypes = {
  theme: PropTypes.object,
  reaction: PropTypes.string,
};

export { CometChatMessageComposer };
